import { Button as AntButton } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(AntButton)`
  font-weight: 500;
  box-shadow: none;
  padding: 0 16px;
  min-height: 32px;
  height: auto;

  &.onboarding {
    min-height: 40px;
  }

  &&.ant-btn-lg {
    padding: 10px 24px;
  }

  &&.ant-btn-sm {
    padding: 6px;
    height: 34px;
  }

  &&.ant-btn-link {
    font-weight: 400;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.ant-btn-primary {
    border: none;
    background: ${({ theme }) => theme.colors.black};

    &:disabled {
      background: ${({ theme }) => theme.colors.gray[10]};
      color: ${({ theme }) => theme.colors.gray[80]};
    }

    &&&:hover {
      &:not(:disabled) {
        background: ${({ theme }) => theme.colors.black};
      }
    }
  }

  &&.ant-btn-default {
    &&&:hover {
      &:not(:disabled) {
        color: inherit;
        border-color: ${({ theme }) => theme.colors.gray[24]};
        opacity 0.6;
      }
    }
  }

  &&&.ant-btn-icon {
    &.anticon {
      vertical-align: sub;

      & svg {
        width: 17px;
        height: 17px;
      }
    }
  }
`;

export default StyledButton;
