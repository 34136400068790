import { Col, Flex, Radio, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';

import { LegalFramework as ILegalFramework } from 'api/requests/generated/generated.schemas';
import Card from 'components/Card';
import Tag, { PremiumTag } from 'components/Tag';
import { Text, Title } from 'components/Typography';
import { QuotaNames } from 'interfaces/enums';
import { IFramework } from 'interfaces/requests/createSetting';
import { frameworks } from 'lib/consts';
import { useEntitlement } from 'lib/hooks';
import { colors } from 'lib/theme/colors';

import { StyledRadio } from './styled';

const { Paragraph } = Typography;

interface ILegalFrameworkProps {
  setFramework: (value: ILegalFramework) => void;
  framework: ILegalFramework;
  disabled?: boolean;
}

interface ILegalFrameworkItemProps {
  framework: IFramework;
  focused: boolean;
  premium?: boolean;
  disabled?: boolean;
}

export const FrameworkItem = ({ premium, disabled, framework, focused }: ILegalFrameworkItemProps) => {
  const { value, name, expansion, icon, region } = framework;

  return (
    <Card focused={focused} className={['framework', disabled ? 'disabled' : ''].join(' ')}>
      <StyledRadio value={value} disabled={disabled} onClick={() => disabled && null}>
        <img src={require(`assets/${icon}.svg`)} alt={icon} width={32} height={24} />
        <Flex style={{ flex: 1 }} vertical>
          <Text weight={500}>{name}</Text>
          <Text size="sm" style={{ color: colors.secondary }} weight={400} ellipsis>
            {expansion}
          </Text>
        </Flex>
        {premium && <PremiumTag />}
        <Tag color="blue-inverse" $bolder size="medium">
          {region}
        </Tag>
      </StyledRadio>
    </Card>
  );
};

const LegalFramework = ({ setFramework, framework, disabled }: ILegalFrameworkProps) => {
  const { subscription, getQuotaPerSubscription } = useEntitlement();
  const [tcfAllowed, setTcfAllowed] = useState(() => !subscription);

  useEffect(() => {
    (async () => {
      if (subscription) {
        const quota = await getQuotaPerSubscription([QuotaNames.FRAMEWORK_TCF]);
        if (quota) {
          const quotaTcf = quota[QuotaNames.FRAMEWORK_TCF];
          if (quotaTcf) {
            setTcfAllowed(quotaTcf.inUse < quotaTcf.limit);
          }
        }
      }
    })();
  }, [subscription]);

  return (
    <>
      <Title level={3}>Framework Type</Title>
      <Paragraph>
        Select the legal framework your configuration should support. Depending on your choice, there will be a default
        setup prepared including all required features to be compliant with the selected framework.{' '}
      </Paragraph>
      <br />
      <Radio.Group value={framework}>
        <Row gutter={[0, 6]}>
          {frameworks.map((currFramework) => {
            const disableTcf = currFramework.value === ILegalFramework.TCF2 && !tcfAllowed;
            const d = disabled || disableTcf;

            return (
              <Col span={24} onClick={() => !d && setFramework(currFramework.value)} key={currFramework.name}>
                <FrameworkItem
                  focused={!d && currFramework.value === framework}
                  framework={currFramework}
                  disabled={d}
                  premium={disableTcf || (subscription?.inTrial && currFramework.isPremium)}
                />
              </Col>
            );
          })}
        </Row>
      </Radio.Group>
    </>
  );
};

export default LegalFramework;
