import { MenuItemType } from 'antd/es/menu/hooks/useItems';

import { LegalFramework } from 'api/requests/generated/generated.schemas';
import { ConfigurationSearchFilter, TrackingEvents } from 'interfaces/enums';
import { IFramework, ExtendedLegalFrameworkName } from 'interfaces/requests/createSetting';

export const AI_DOCS = 'https://docs.usercentrics.com/#/account-interface';
export const UC_DOCS = 'https://docs.usercentrics.com/#/browser-cmp?id=browser-support';
export const AI_DOCS_GEORULES = 'https://docs.usercentrics.com/#/geolocation-rules';
export const COUNTRIES_WITH_MANDATORY_VAT_ID = ['CH', 'NO'];
export const SCRIPT_TAG_V2_DOCUMENTATION_LINK = 'https://docs.usercentrics.com/#/browser-cmp?id=implementation';
export const SCRIPT_TAG_V3_HOW_TO_MIGRATE_LINK = 'https://usercentrics.com/docs/web/migration/migration-from-v2/';
export const GOOGLE_CONSENT_MODE =
  'https://usercentricssupport.zendesk.com/hc/en-us/articles/12206549906460-How-to-check-if-Google-Consent-Mode-is-properly-implemented';
export const INTEGRATION_WITH_THIRD_PARTY =
  'https://usercentricssupport.zendesk.com/hc/en-us/sections/12296423694236-Integration-with-3rd-party-tools';

//! NOTE: Might change in the future, currently this is valid one for DMA
export const DMA_COUPON = 'DMAGG1MFREE';

// ================ Amplitude Related =====================
export const AMPLITUDE_TEMPLATE_ID = 'Sk9kb5VoOi-7';

export const SURVEY_ID = '03c8600c-9e01-47a8-b153-44c52c7d5b08';
// ========================================================
export enum ONBOARDING_TYPE {
  DMA = 'dma',
  STANDARD = 'standard',
  SUMMER_2024 = 'sc24',
}

export enum ONBOARDING_PRICE_PLAN {
  SUMMER_2024 = 'Summer2024',
}

export enum DMA_CONNECTIONS {
  GOOGLE = 'google-oauth2',
}

export enum QUERY_PARAMS {
  one_pricing_plan = 'pricing_plan',
}

export enum USER_AUTH0_PARAMETERS {
  one_pricing_plan = 'one_pricing_plan',
  uc_coupon = 'uc_coupon',
  uc_subscription_type = 'uc_subscription_type',
  uc_lead_source = 'uc_lead_source',
  uc_onboarding = 'uc_onboarding',
}

export enum TRIAL_COOKIES {
  TRIAL_USED = 'trial_used',
  TRIAL_PLAN = 'trial_plan',
}

export const frameworks: IFramework[] = [
  {
    name: LegalFramework.GDPR,
    value: LegalFramework.GDPR,
    expansion: 'General Data Protection Regulation',
    icon: 'eu',
    region: 'Europe',
  },
  {
    name: ExtendedLegalFrameworkName['TCF 2.2'],
    value: LegalFramework.TCF2,
    expansion: 'Transparency & Consent Framework 2.2',
    icon: 'iab',
    region: 'Europe',
    isPremium: true,
  },
  {
    name: LegalFramework.CCPA,
    value: LegalFramework.CCPA,
    expansion: 'California Consumer Privacy Act',
    icon: 'us',
    region: 'California',
  },
  {
    name: LegalFramework.CPRA,
    value: LegalFramework.CPRA,
    expansion: 'California Privacy Rights Act',
    icon: 'us',
    region: 'California',
  },
  {
    name: LegalFramework.CPA,
    value: LegalFramework.CPA,
    expansion: 'Colorado Privacy Act',
    icon: 'us',
    region: 'Colorado',
  },
  {
    name: LegalFramework.CTDPA,
    value: LegalFramework.CTDPA,
    expansion: 'Connecticut Data Privacy Act',
    icon: 'us',
    region: 'Connecticut',
  },
  {
    name: LegalFramework.UCPA,
    value: LegalFramework.UCPA,
    expansion: 'Utah Consumer Privacy Act',
    icon: 'us',
    region: 'Utah',
  },
  {
    name: LegalFramework.VCDPA,
    value: LegalFramework.VCDPA,
    expansion: 'Virginia Consumer Data Protection Act ',
    icon: 'us',
    region: 'Virginia',
  },
];

export const searchFilters: MenuItemType[] = [
  { label: 'All', key: ConfigurationSearchFilter.All },
  { label: 'Configuration-ID', key: ConfigurationSearchFilter.ConfigurationId },
  {
    label: 'Configuration Name',
    key: ConfigurationSearchFilter.ConfigurationName,
  },
  { label: 'App-ID', key: ConfigurationSearchFilter.AppId },
  { label: 'Data Controller', key: ConfigurationSearchFilter.DataController },
  { label: 'Domain', key: ConfigurationSearchFilter.Domain },
  { label: 'Email', key: ConfigurationSearchFilter.Email },
  { label: 'Subscription-ID', key: ConfigurationSearchFilter.SubscriptionId },
];

export const cacheableAmplitudeEvents = [
  TrackingEvents.EMAIL_VERIFICATION_COMPLETED,
  TrackingEvents.EMAIL_VERIFICATION_VISITED,
  TrackingEvents.CTA_FREE_TRIAL,
];

export function getBrowserScriptTagV2(rulesetId: string, tcfEnabled: boolean): string {
  const scriptSrc = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
  if (tcfEnabled) {
    return `<script id="usercentrics-cmp" src="${scriptSrc}" data-ruleset-id="${rulesetId}" data-tcf-enabled async></script>`;
  } else {
    return `<script id="usercentrics-cmp" src="${scriptSrc}" data-ruleset-id="${rulesetId}" async></script>`;
  }
}

export function getBrowserScriptTagV3(rulesetId: string, tcfEnabled: boolean): string {
  if (tcfEnabled) {
    return `<script src="https://web.cmp.usercentrics.eu/tcf/stub.js"></script>
    <script id="usercentrics-cmp" src="https://web.cmp.usercentrics.eu/ui/loader.js" data-ruleset-id="${rulesetId}" async></script>`;
  } else {
    return `<script id="usercentrics-cmp" src="https://web.cmp.usercentrics.eu/ui/loader.js" data-ruleset-id="${rulesetId}" async></script>`;
  }
}

export function getBrowserScriptTagV3WithSettingId(
  settingId: string,
  tcfEnabled: boolean,
  autoBlockingEnabled?: boolean,
): string {
  const autoBlockingScript =
    '<script type="application/javascript" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>\n';
  const tcfScript = '<script src="https://web.cmp.usercentrics.eu/tcf/stub.js"></script>\n';
  const cmpScript = `<script id="usercentrics-cmp" src="https://web.cmp.usercentrics.eu/ui/loader.js" data-settings-id="${settingId}" async></script>\n`;

  return `${autoBlockingEnabled ? autoBlockingScript : ''}${tcfEnabled ? tcfScript : ''}${cmpScript}`;
}
