import { TagProps } from 'antd';

import { Lightning, Person as PersonIcon, Stars as StarsIcon } from 'components/Icons';

import { StyledTag } from './styled';

export interface ITagProps extends TagProps {
  $bolder?: boolean;
  $bold?: boolean;
  size?: 'small' | 'middle' | 'normal' | 'medium' | 'large' | 'square-large' | 'square-medium' | 'square-small';
  transparent?: boolean;
  $newStyle?: boolean;
  $noMargin?: boolean;
}

const Tag = ({ children, $bold = true, size = 'small', $noMargin = false, ...props }: ITagProps) => {
  return (
    <StyledTag bordered={false} $bold={$bold} $noMargin={$noMargin} size={size} {...props}>
      {children}
    </StyledTag>
  );
};

const PermissionTag = (props: ITagProps) => <Tag icon={<PersonIcon />} {...props} />;
const PremiumTag = (props: ITagProps) => (
  <Tag
    size={props?.size ?? 'medium'}
    color={props.color ?? 'orange'}
    bordered
    icon={<Lightning color="gradient-gold" />}
    {...props}
  />
);

const PremiumFeatureTag = (props: ITagProps) => <PremiumTag {...props}>Premium Feature</PremiumTag>;

const NewTag = ({ children, ...props }: ITagProps) => (
  <Tag $newStyle {...props}>
    <StarsIcon />
    {children}
  </Tag>
);

export default Tag;

export { PermissionTag, NewTag, PremiumTag, PremiumFeatureTag };
