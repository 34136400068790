import { Layout, Flex } from 'antd';

import Logo from 'components/Logo';
import { PremiumTag } from 'components/Tag';
import { Title } from 'components/Typography';

import { OnboardingBlock, OnboardingParagraph } from './styled';

const { Sider } = Layout;

interface IOnboardingSideBarProps {
  title: string;
  description: string;
  tagText?: string;
}

const OnboardingSideBar = ({ title, description }: IOnboardingSideBarProps) => (
  <Sider width="540px">
    <OnboardingBlock align="start" vertical>
      <Logo width={231} />
      <Flex style={{ height: '100%' }} justify="center" vertical>
        <Flex>
          <PremiumTag>Premium Trial</PremiumTag>
        </Flex>
        <Title style={{ fontSize: '3.25rem', margin: '12px 0' }}>{title}</Title>
        <OnboardingParagraph> {description}</OnboardingParagraph>
      </Flex>
    </OnboardingBlock>
  </Sider>
);

export default OnboardingSideBar;
