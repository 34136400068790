import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Divider, Flex, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { useRecommendedPricePlan } from 'api/requests';
import {
  RecommendedPricePlanBySubscriptionEnrichedOutDto,
  SubscriptionPricePlan,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Title, Text, Link } from 'components/Typography';
import { useManageSubscription } from 'lib/hooks';

import PricePlanCard from './PricePlanCard';
import { Container, Content, SwiperContainer } from './styled';

const SCROLL_STEP = 550;

interface PricePlanSelectionProps {
  onUpgrade: (plan: RecommendedPricePlanBySubscriptionEnrichedOutDto) => Promise<void>;
}

const PricePlanSelection = ({ onUpgrade }: PricePlanSelectionProps) => {
  const swiperRef = useRef<HTMLElement>(null);
  const [disabledButton, setDisabledButton] = useState<'next' | 'prev' | null>('prev');
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const { subscription } = useManageSubscription();

  const { data, isLoading } = useRecommendedPricePlan(
    subscription?.id || '',
    {
      quantity: subscription?.package.includes('Trial') ? 1 : subscription?.maxValue || subscription?.quantity || 1,
    },
    { swr: { enabled: Boolean(subscription?.id) && Boolean(subscription?.quantity) } },
  );

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.clientWidth < swiperRef.current.scrollWidth && !isLoading) {
      setShowScrollButtons(true);
    }
  }, [swiperRef.current, isLoading]);

  const handleScroll = (direction: 'next' | 'prev') => {
    if (swiperRef && swiperRef.current) {
      if (direction === 'next') {
        swiperRef.current.scrollLeft += SCROLL_STEP;

        if (
          swiperRef.current.scrollWidth - swiperRef.current.scrollLeft - SCROLL_STEP <=
          swiperRef.current.clientWidth
        ) {
          setDisabledButton('next');
          return;
        }
      }

      if (direction === 'prev') {
        swiperRef.current.scrollLeft -= SCROLL_STEP;

        if (swiperRef.current.scrollLeft - SCROLL_STEP <= 0) {
          setDisabledButton('prev');
          return;
        }
      }

      setDisabledButton(null);
    }
  };

  const recommendedPricePlans = data?.body?.recommendedPricePlans;

  if (!recommendedPricePlans) {
    return null;
  }

  const isAllowedToDowngrade = recommendedPricePlans?.[0]?.pricePlan === SubscriptionPricePlan.FreeExtended;

  return (
    <Container>
      <Content vertical gap={24} justify="center">
        {isLoading || !data?.body ? (
          <Spin />
        ) : (
          <>
            <Flex justify="space-between">
              <Title level={2}>Select a plan</Title>
              {showScrollButtons && (
                <Flex gap={8}>
                  <Button disabled={disabledButton === 'prev'} onClick={() => handleScroll('prev')}>
                    <ArrowLeftOutlined />
                  </Button>
                  <Button disabled={disabledButton === 'next'} onClick={() => handleScroll('next')}>
                    <ArrowRightOutlined />
                  </Button>
                </Flex>
              )}
            </Flex>

            <SwiperContainer gap={16} ref={swiperRef}>
              {(isAllowedToDowngrade ? recommendedPricePlans.slice(1) : recommendedPricePlans).map((plan) => (
                <PricePlanCard currency={data.body.currencyCode} key={plan.itemPriceId} plan={plan} />
              ))}
            </SwiperContainer>
            {isAllowedToDowngrade && (
              <>
                <Divider>
                  <Text size="sm" weight={700} type="secondary">
                    OR
                  </Text>
                </Divider>
                <Flex justify="center">
                  <Text type="secondary">
                    Downgrade to our{' '}
                    <Link onClick={() => onUpgrade(recommendedPricePlans[0])} weight={500}>
                      Free Plan
                    </Link>
                  </Text>
                </Flex>
              </>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default PricePlanSelection;
