/* eslint-disable prefer-promise-reject-errors */

import { useAuth0 } from '@auth0/auth0-react';
import { Form, Flex, Divider, Typography, Radio, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib';
import Cookies from 'js-cookie';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Input } from 'components/Form';
import Item from 'components/Form/Item';
import { Expand } from 'components/Icons';
import { Text, Title } from 'components/Typography';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { USER_AUTH0_PARAMETERS, ONBOARDING_TYPE, frameworks } from 'lib/consts';
import { trackEvent } from 'lib/helpers/amplitude';
import { domainRegexp } from 'lib/helpers/validation';
import { colors } from 'lib/theme/colors';
import { FrameworkItem } from 'pages/Companies/Configurations/Modals/CreateConfiguration/Steps/LegalFramework';

import { Collapse } from './styled';
import { Box } from '../../styled';

const { Paragraph } = Typography;

interface IDetailsProps {
  form: FormInstance;
  setDisabled: (value: boolean) => void;
}

const Details = ({ form, setDisabled }: IDetailsProps) => {
  const values = Form.useWatch([], form);
  const { user } = useAuth0();
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const subscriptionType = user?.one_pricing_plan
    ? SubscriptionType.web
    : user?.uc_subscription_type || SubscriptionType.web;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        if (values?.framework) {
          setDisabled(false);
          TagManager.dataLayer({
            dataLayer: {
              event: TrackingEvents.ONBOARDING_DETAILS_FILLED,
              [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
              [TrackingVariables.PLATFORM]: subscriptionType,
              [TrackingVariables.CONFIGURATION_FRAMEWORK]: values?.framework,
              [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
                Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
              ),
            },
          });
          trackEvent(TrackingEvents.ONBOARDING_DETAILS_FILLED, {
            [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
            [TrackingVariables.PLATFORM]: subscriptionType,
            [TrackingVariables.CONFIGURATION_FRAMEWORK]: values?.framework,
            [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
              Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
            ),
          });
        } else {
          setDisabled(true);
        }
      },
      () => {
        setDisabled(true);
      },
    );
  }, [values]);

  useEffect(() => {
    if (
      !form.getFieldValue('framework') ||
      frameworks.slice(0, 3).some((framework) => framework.value === form.getFieldValue('framework'))
    ) {
      setShowMoreClicked(false);
    } else {
      setShowMoreClicked(true);
    }
  }, []);

  return (
    <Form name="company-details" form={form} initialValues={{ companyName: '' }} layout="vertical">
      <Flex gap={24} vertical>
        <Flex gap={40}>
          <Flex vertical flex="0 0 220px">
            <Title level={3} data-testid="empty-placeholder:title">
              Account Information
            </Title>
            <Paragraph type="secondary">Enter your company name.</Paragraph>
          </Flex>
          <Flex flex={1}>
            <Box style={{ width: '100%' }}>
              <Item
                label="Account Name"
                name="companyName"
                colon={false}
                rules={[{ required: true, message: 'Required field' }]}
                weight="medium"
                requiredMark={false}
              >
                <Input placeholder="Account Name" autoFocus />
              </Item>
            </Box>
          </Flex>
        </Flex>
        <Flex gap={40}>
          <Flex vertical flex="0 0 220px">
            <Title level={3} data-testid="empty-placeholder:title">
              Website
            </Title>
            <Paragraph type="secondary">
              Enter where you want to show consent banner. We will scan your site for integration tracking technologies.
            </Paragraph>
          </Flex>
          <Flex flex={1}>
            <Box style={{ width: '100%' }}>
              <Item
                label="Domain"
                name="domain"
                colon={false}
                weight="medium"
                rules={[
                  { required: true, message: 'Required field' },
                  { pattern: domainRegexp, message: 'Please enter a valid domain string' },
                ]}
                requiredMark={false}
              >
                <Input placeholder="mycompany.com" />
              </Item>
              <Divider style={{ margin: '16px 0' }} />
              <Collapse
                bordered={false}
                ghost
                expandIconPosition="end"
                items={[
                  {
                    key: '1',
                    label: <Text weight={500}>How does the scan work?</Text>,
                    children: (
                      <Paragraph type="secondary">
                        After scanning, all third-party services found on your domain will appear in Service
                        Settings/DPS Scanner. To ensure compliance, include all legally relevant results in your consent
                        banner.
                      </Paragraph>
                    ),
                  },
                ]}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex gap={40}>
          <Flex vertical flex="0 0 220px">
            <Title level={3} data-testid="empty-placeholder:title">
              Legal Framework
            </Title>
            <Paragraph type="secondary">Select the legal framework for your consent banner.</Paragraph>
          </Flex>
          <Flex flex={1}>
            <Box style={{ width: '100%' }}>
              <Item name="framework" required>
                <Radio.Group>
                  <Row gutter={[0, 8]}>
                    {frameworks.map((framework, index) => (
                      // Need to show only 3 frameworks by default
                      <Col key={framework.value} span={showMoreClicked || index < 3 ? 24 : 0}>
                        <FrameworkItem
                          focused={framework.value === values?.framework}
                          framework={framework}
                          premium={Boolean(framework?.isPremium)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Item>
              <Row justify="center">
                {!showMoreClicked && (
                  <Button type="link" onClick={() => setShowMoreClicked(true)}>
                    Show More <Expand style={{ margin: 0 }} color={colors.primary} />
                  </Button>
                )}
              </Row>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Form>
  );
};

export default Details;
