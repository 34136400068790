import { SubscriptionStatus, SubscriptionDto } from 'api/requests/generated/generated.schemas';
import { Items } from 'components/SubscriptionCard/Content';

export const formatDate = (date: string | null) => new Date(date as string).toLocaleDateString('en-GB');

export const getSubscriptionDescription = (subscription: SubscriptionDto) => {
  switch (subscription.status) {
    case 'active':
      return `Active since ${formatDate(subscription.startedAt)}`;
    case 'non_renewing':
      return `Cancels on ${formatDate(subscription.finishedAt)}`;
    case 'cancelled':
      return `Cancelled on ${formatDate(subscription.finishedAt)}`;
    case 'future':
      if (subscription.trialStartedAt) {
        return `Trials starts on ${formatDate(subscription.trialStartedAt)}`;
      } else {
        return `Activates on ${formatDate(subscription.startedAt)}`;
      }
    case 'in_trial':
      return `Trials ends on ${formatDate(subscription.trialFinishedAt)}`;
  }
};

export const getSubscriptionStatusName = (status: SubscriptionStatus, isExpiredTrial?: boolean) => {
  if (isExpiredTrial) {
    return 'Trial Expired';
  }

  switch (status) {
    case 'in_trial':
      return 'In Trial';
    case 'non_renewing':
      return 'Non Renewing';
    default:
      return status.toUpperCaseFirst();
  }
};

export const getColorByStatus = (status: SubscriptionStatus, isExpiredTrial?: boolean) => {
  if (isExpiredTrial) {
    return 'gold';
  }

  switch (status) {
    case 'cancelled':
    case 'non_renewing':
      return 'red';
    case 'active':
      return 'green';
    case 'future':
      return 'default';
    case 'in_trial':
      return 'blue-inverse';
    default:
      return 'default';
  }
};

const defaultList = ['price', 'domains', 'configurations', 'sessions', 'payment'] as Items[];

export const getItemsToShow = (isBillingPage?: boolean): { [key in SubscriptionStatus]: Items[] } =>
  isBillingPage
    ? {
        [SubscriptionStatus.active]: ['billing', 'subscriptionId', 'price', 'domains', 'configurations', 'sessions'],
        [SubscriptionStatus.in_trial]: [
          'status',
          'subscriptionId',
          'price',
          'domains',
          'configurations',
          'sessions',
          'payment',
        ],
        [SubscriptionStatus.cancelled]: ['status', 'subscriptionId', 'price', 'domains', 'sessions', 'payment'],
        [SubscriptionStatus.future]: defaultList,
        [SubscriptionStatus.non_renewing]: defaultList,
      }
    : {
        [SubscriptionStatus.active]: [
          'status',
          'billing',
          'subscriptionId',
          'price',
          'domains',
          'configurations',
          'sessions',
          'payment',
        ],
        [SubscriptionStatus.in_trial]: defaultList,
        [SubscriptionStatus.future]: defaultList,
        [SubscriptionStatus.cancelled]: defaultList,
        [SubscriptionStatus.non_renewing]: defaultList,
      };
