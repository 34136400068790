import { Flex, Form } from 'antd';
import { FormInstance } from 'antd/lib';
import { useEffect, useState } from 'react';

import AddressItem, { type Address } from 'components/AddressItem';
import { Input, Item } from 'components/Form';
import { Title, Text } from 'components/Typography';
import VatInput from 'components/VatInput';
import { useManageSubscription } from 'lib/hooks';

export interface IBillingInformationForm extends Address {
  name: string;
}

interface IBillingInformationFormProps {
  form: FormInstance<IBillingInformationForm>;
  onValuesChange: (isValid: boolean) => void;
  estimate: () => Promise<void>;
}

const BillingInformationForm = ({ form, onValuesChange, estimate }: IBillingInformationFormProps) => {
  const { account } = useManageSubscription();
  const [disableValidate, setDisableValidate] = useState(true);

  const values = Form.useWatch([], { form, preserve: true });

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        onValuesChange(true);
        setDisableValidate(false);
      },
      () => {
        onValuesChange(false);
        setDisableValidate(true);
      },
    );
  }, [values]);

  const onVatNumberInputChange = () => {
    onValuesChange(false);
  };

  const onVatNumberValidationChanged = async (isValid: boolean) => {
    if (isValid) {
      onValuesChange(true);
      if (estimate) {
        await estimate();
      }
    } else {
      onValuesChange(false);
    }
  };

  if (!account) return null;

  return (
    <Flex vertical gap={24}>
      <Title level={1}>Billing Information</Title>
      <Form
        form={form}
        name="billing-information"
        initialValues={{
          name: account?.name,
          address: {
            city: account?.city,
            country: account?.country,
            street: account?.street,
            zip: account?.postcode,
          },
        }}
      >
        <Flex vertical gap={20}>
          <Flex vertical gap={8}>
            <Item
              name="name"
              label="Account Name on invoice"
              colon={false}
              required
              requiredMark={false}
              rules={[{ required: true, message: 'Account name is required' }]}
            >
              <Input placeholder="Account name" />
            </Item>
            <Text size="sm" type="secondary">
              Enter the name of your company that should appear on your invoices.
            </Text>
          </Flex>

          <AddressItem<IBillingInformationForm> form={form} label="Billing Address" />
          <VatInput
            address={values?.address}
            country={values?.address?.country}
            accountId={account?.id}
            initialVatNumber={account?.vatNumber || ''}
            onChange={onVatNumberInputChange}
            onIsValidChanged={onVatNumberValidationChanged}
            hideTooltip
            disableValidate={disableValidate}
          />
        </Flex>
      </Form>
    </Flex>
  );
};

export default BillingInformationForm;
