import { ConfigProvider } from 'antd';
import { Outlet } from 'react-router-dom';

import Header from 'components/Header';
import { colors } from 'lib/theme/colors';

import { Layout } from './styled';

const ManageSubscriptionLayout = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            bodyBg: colors.gray[8],
            siderBg: colors.gray[8],
          },
        },
      }}
    >
      <Layout>
        <Header isManageSubscriptionHeader />
        <Layout style={{ padding: '60px 40px 40px 40px' }}>
          <Outlet />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default ManageSubscriptionLayout;
