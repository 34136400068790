import { Row } from 'antd';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Outlet, useNavigate } from 'react-router-dom';

import { SubscriptionPricePlan, SubscriptionType } from 'api/requests/generated/generated.schemas';
import ContentFooter from 'components/Footer';
import Header from 'components/Header';
import UpgradePlan from 'components/Modal/UpgradePlan';
import SideBar from 'components/SideBar';
import GetFullAccessBar from 'components/StatusBar/GetFullAccessBar';
import { TrackingEvents, TrackingVariables, LS_KEYS, OnePricingPricePlans } from 'interfaces/enums';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { getPlanName } from 'lib/helpers';
import { trackEvent } from 'lib/helpers/amplitude';
import { dismissUpgrade } from 'lib/helpers/overusage';
import { useTrial, useUpgradePlan, usePrivileges, useUsageStatus, useSelectedCompany } from 'lib/hooks';

import { Content, Layout } from './styled';
import NewFeatureBar from '../NewFeatureBar';
import TrialStatusBar from '../TrialStatusBar';
import UsageStatusBar from '../UsageStatusBar';

const ucUpsellNewFeatureDissmissed = localStorage.getItem(LS_KEYS.UC_UPSELL_NEW_FEATURE_DISSMISS);

const MainLayout = () => {
  const { subscription, setPlanToUpgrade, exceeded } = useUpgradePlan();
  const { readonly, shouldShowLockoutScreen, shouldShowTrialBar, subscriptionsInTrial = [], expiredTrial } = useTrial();
  const { shouldShowUsageBar } = useUsageStatus();
  const { isCSA, hasAccounts } = usePrivileges();
  const [isLockoutOpen, setIsLockoutOpen] = useState(true);
  const { billingCustomerId } = useSelectedCompany();
  const navigate = useNavigate();
  const [showNewFeature, setShowNewFeature] = useState(false);

  useEffect(() => {
    setShowNewFeature(
      () =>
        new Date().getTime() <= new Date('08-07-2024').getTime() &&
        !ucUpsellNewFeatureDissmissed &&
        !isCSA &&
        hasAccounts,
    );
  }, [isCSA, hasAccounts]);

  useEffect(() => {
    setIsLockoutOpen(Boolean(shouldShowLockoutScreen));
  }, [shouldShowLockoutScreen]);

  const onUpgradeClose = () => {
    if (exceeded?.forcedUpgrade && subscription) {
      trackEvent(TrackingEvents.UPGRADE_SUBSCRIPTION_DISMISSED, {
        [TrackingVariables.SUBSCRIPTION_ID]: subscription.id,
      });
      dismissUpgrade(subscription.id);
    }
    setPlanToUpgrade(null);
  };

  if (isLockoutOpen && expiredTrial) {
    TagManager.dataLayer({
      dataLayer: {
        event: TrackingEvents.TRIAL_EXPIRED,
        [TrackingVariables.PLAN_NAME]: getPlanName(expiredTrial?.pricePlan, expiredTrial?.type),
        [TrackingVariables.TRIAL_EXPIRED_DATE]: expiredTrial.trialFinishedAt || '',
        [TrackingVariables.PLATFORM]: expiredTrial.type || SubscriptionType.web,
      },
    });

    trackEvent(TrackingEvents.TRIAL_EXPIRED, {
      [TrackingVariables.PLAN_NAME]: getPlanName(expiredTrial?.pricePlan, expiredTrial?.type),
      [TrackingVariables.TRIAL_EXPIRED_DATE]: expiredTrial.trialFinishedAt || '',
      [TrackingVariables.PLATFORM]: expiredTrial.type || SubscriptionType.web,
    });
    return <UpgradePlan open trial={expiredTrial} readonly={readonly} onClose={() => setIsLockoutOpen(false)} />;
  }

  const onDissmissNewFeature = (shouldNavigate?: boolean) => {
    localStorage.setItem(LS_KEYS.UC_UPSELL_NEW_FEATURE_DISSMISS, '1');
    setShowNewFeature(false);
    if (shouldNavigate) {
      navigate('/billing/account#subscription-row', { state: { billingCustomerId } });
      trackEvent(TrackingEvents.UC_UPSELL_FEATURE_EXPLORED);
    } else {
      trackEvent(TrackingEvents.UC_UPSELL_FEATURE_DISMISSED);
    }
  };

  const { regularSubscriptions, onePricingSubscriptions } = subscriptionsInTrial.reduce<{
    regularSubscriptions: IUpgradeSubscription[];
    onePricingSubscriptions: IUpgradeSubscription[];
  }>(
    (acc, curr) => {
      if (OnePricingPricePlans.includes(curr.pricePlan as SubscriptionPricePlan)) {
        return {
          ...acc,
          onePricingSubscriptions: [...acc.onePricingSubscriptions, curr],
        };
      }

      return {
        ...acc,
        regularSubscriptions: [...acc.regularSubscriptions, curr],
      };
    },
    { regularSubscriptions: [], onePricingSubscriptions: [] },
  );

  const barsAmount =
    Number(Boolean(shouldShowTrialBar)) * regularSubscriptions.length +
    Number(shouldShowUsageBar || showNewFeature) +
    onePricingSubscriptions.length;

  return (
    <Layout>
      {subscription && <UpgradePlan open onClose={onUpgradeClose} />}
      <Header
        isMainHeader
        bottomChildren={[
          {
            content: (
              <Row key="feature">
                {shouldShowUsageBar ? (
                  <UsageStatusBar />
                ) : (
                  showNewFeature && (
                    <NewFeatureBar
                      feature="Keep track of your monthly subscription usage on the Account & Billing page."
                      onClose={() => onDissmissNewFeature()}
                      onExplore={() => {
                        onDissmissNewFeature(true);
                      }}
                    />
                  )
                )}
              </Row>
            ),
          },
          {
            content: (
              <Row key="trial">
                {shouldShowTrialBar && <TrialStatusBar readonly={readonly} trials={regularSubscriptions} />}
              </Row>
            ),
          },
        ]}
        topChildren={[
          {
            content:
              onePricingSubscriptions?.length > 0
                ? onePricingSubscriptions.map((onePricingSubscription) => (
                    <GetFullAccessBar
                      key="fullAccees"
                      trialFinishedAt={onePricingSubscription.trialFinishedAt}
                      subscriptionId={onePricingSubscription.id}
                    />
                  ))
                : null,
          },
        ]}
      />

      <Layout style={{ paddingTop: 60 * (barsAmount + 1) }}>
        <SideBar />
        <Content>
          <Outlet />
          <ContentFooter />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
