import { createContext, useEffect, useState } from 'react';

import { getEntitlementBySubscriptionId, getQuotaBySubscriptionId, useGetMyAccounts } from 'api/requests';
import {
  EntitlementDto,
  QuotaDto,
  SubscriptionStatus,
  AccountOutDto,
  SubscriptionDto,
  SubscriptionPricePlan,
} from 'api/requests/generated/generated.schemas';
import { QuotaNames, OnePricingPricePlans } from 'interfaces/enums';

type FormattedQuotaResult = Partial<Record<QuotaNames, { inUse: number; limit: number; inUseDomains?: number }>>;
type BaseSubscription = { inTrial: boolean; id: string; plan: SubscriptionPricePlan };

type ContextType = {
  plan: SubscriptionPricePlan | null;
  entitlement: EntitlementDto | null;
  subscription: BaseSubscription | null;
  applyEntitlementsPerSubscription: () => void;
  getQuotaPerSubscription: (quotaNames: QuotaNames[]) => Promise<FormattedQuotaResult | null>;
  storeSubscription: (billingCustomerId: string) => void;
  getEntitlement: (key: keyof EntitlementDto) => { disable: boolean; showPremium: boolean };
  clearSubscription: () => void;
};

export const EntitlementContext = createContext<ContextType>({
  plan: null,
  entitlement: null,
  subscription: null,
  applyEntitlementsPerSubscription: () => null,
  getQuotaPerSubscription: async () => null,
  storeSubscription: () => null,
  getEntitlement: () => ({ disable: false, showPremium: false }),
  clearSubscription: () => null,
});

export const isOnePricingSub = (subscription: SubscriptionDto | null) => {
  if (!subscription) {
    return false;
  }

  const allowedStatuses: SubscriptionStatus[] = [SubscriptionStatus.in_trial, SubscriptionStatus.active];
  return (
    allowedStatuses.includes(subscription.status) &&
    OnePricingPricePlans.includes(subscription.pricePlan as Partial<SubscriptionPricePlan>)
  );
};

export const getBaseSubscriptionInfo = (account?: AccountOutDto) => {
  if (!account) return null;
  const sub = account?.subscriptions?.find(isOnePricingSub);
  if (!sub) return null;
  return {
    inTrial: sub.status === SubscriptionStatus.in_trial,
    plan: sub.pricePlan as SubscriptionPricePlan,
    id: sub.id,
  };
};

const EntitlementProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const [plan, setPlan] = useState<SubscriptionPricePlan | null>(null);
  const [entitlement, setEntitlement] = useState<EntitlementDto | null>(null);
  // Store only OnePricing "trial" or "active" subscriptions
  const [subscription, setSubscription] = useState<null | BaseSubscription>(null);
  const [billingCustomerId, setBillingCustomerId] = useState<null | string>(null);
  const { data: accounts } = useGetMyAccounts();

  const clearSubscription = () => {
    setSubscription(null);
    setBillingCustomerId(null);
  };

  const storeSubscription = async (id: string) => {
    setBillingCustomerId(id);
  };

  const getEntitlement = (key: keyof EntitlementDto) => {
    if (!subscription || !entitlement) return { disable: false, showPremium: false };
    if (subscription.inTrial) return { disable: false, showPremium: true };
    return { disable: !Boolean(entitlement[key]), showPremium: !Boolean(entitlement[key]) };
  };

  const applyEntitlementsPerSubscription = async () => {
    if (!subscription) return null;
    try {
      const ent = await getEntitlementBySubscriptionId(subscription.id);
      setEntitlement(ent?.body?.entitlement);
      setPlan(ent?.body?.plan as SubscriptionPricePlan);
    } catch (e) {
      return null;
    }
  };

  const getQuotaPerSubscription = async (quotaNames: QuotaNames[]): Promise<null | FormattedQuotaResult> => {
    if (!subscription) return null;
    try {
      const q = await getQuotaBySubscriptionId(subscription.id, { filter: quotaNames.join(',') });
      const quota = q?.body?.quota;
      if (quota) {
        return (Object.keys(quota) as Array<keyof QuotaDto>).reduce(
          (acc, quotaName) => ({
            ...acc,
            [quotaName]: {
              ...quota[quotaName],
              limit: (typeof quota[quotaName]?.limit !== 'number'
                ? Number.MAX_SAFE_INTEGER
                : quota[quotaName]?.limit) as number,
            },
          }),
          {},
        );
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (accounts?.body && billingCustomerId) {
      const currentAccount = accounts?.body?.find((acc) => acc.id === billingCustomerId);
      if (currentAccount) {
        const sub = getBaseSubscriptionInfo(currentAccount);
        if (sub) {
          setSubscription(sub);
        }
      }
    }
  }, [accounts, billingCustomerId]);

  useEffect(() => {
    if (subscription) {
      applyEntitlementsPerSubscription();
    }
  }, [subscription?.id]);

  return (
    <EntitlementContext.Provider
      value={{
        entitlement,
        plan,
        applyEntitlementsPerSubscription,
        getQuotaPerSubscription,
        subscription,
        storeSubscription,
        getEntitlement,
        clearSubscription,
      }}
    >
      {children}
    </EntitlementContext.Provider>
  );
};

export default EntitlementProvider;
