import { Form, FormInstance, Spin } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetMyAccounts } from 'api/requests';
import { OnboardingStatusEnum, setOnboardingStatus } from 'api/requests/onboarding';
import { postSurveyAnswers } from 'api/requests/survey';
import { ISurveyRequestAnswer } from 'interfaces/responses';
import { SURVEY_ID } from 'lib/consts';
import { trackOnboardingSurveySubmitEvent } from 'lib/helpers/analytics';
import openSettingInAI from 'lib/helpers/openSettingInAI';

import { getOneClickAccounts } from './utils';
import Survey from '../Survey';

const GoogleOneClickOnboarding = () => {
  const navigate = useNavigate();
  const [surveyForm] = Form.useForm<FormInstance>();
  const [noRetriesLeft, setNoRetriesLeft] = useState(false);

  const {
    data: accounts,
    isLoading,
    error,
    mutate,
  } = useGetMyAccounts({
    swr: {
      onErrorRetry: (err, key, config, revalidate, { retryCount }) => {
        if (retryCount === 3) {
          setNoRetriesLeft(true);
          return;
        }
        setTimeout(() => revalidate({ retryCount }), 2000);
      },
      onSuccess: (data) => {
        if (!data.body.length && !noRetriesLeft) {
          setNoRetriesLeft(true);
          mutate();
        }
      },
    },
  });

  if (!noRetriesLeft && (isLoading || error)) {
    return <Spin fullscreen />;
  }

  const { settingIds, oneclickAccounts } = getOneClickAccounts(accounts?.body || []);

  if (!settingIds.length) {
    navigate('/');
    return null;
  }

  const onClose = async () => {
    try {
      await setOnboardingStatus(OnboardingStatusEnum.DONE);

      if (settingIds.length === 1) {
        openSettingInAI(settingIds[0], 'V2', '_self');
      } else {
        navigate('/');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSurveySubmit = async (surveyId: string) => {
    const answers = Object.entries(surveyForm.getFieldsValue(true)).map(([questionId, value]) => ({
      questionId,
      value,
    })) as ISurveyRequestAnswer[];

    try {
      await postSurveyAnswers(surveyId, { answers, references: { ucBillingAccount: oneclickAccounts[0]?.id } });
      trackOnboardingSurveySubmitEvent(answers);

      await setOnboardingStatus(OnboardingStatusEnum.DONE);

      if (settingIds.length === 1) {
        openSettingInAI(settingIds[0], 'V2', '_self');
      } else {
        navigate('/');
      }
    } catch (err) {
      console.error(err);
    }
  };

  return <Survey onClose={onClose} onSubmit={onSurveySubmit} surveyId={SURVEY_ID} form={surveyForm} />;
};

export default GoogleOneClickOnboarding;
