import { Divider, Flex, Typography } from 'antd';
import { useEffect } from 'react';

import { RecommendedPricePlanBySubscriptionEnrichedOutDto } from 'api/requests/generated/generated.schemas';
import { Title, Text } from 'components/Typography';
import { Currency } from 'interfaces/enums';
import { useManageSubscription } from 'lib/hooks';
import { colors } from 'lib/theme/colors';
import { FeaturesList } from 'pages/ManageSubscription/components/FeaturesList';

import { StyledCard } from './styled';
import { getPlanDetailsById } from './utils';

const { Paragraph, Link } = Typography;

interface IPricePlanCardProps {
  plan: RecommendedPricePlanBySubscriptionEnrichedOutDto;
  currency: string;
}

const PricePlanCard = ({ plan, currency }: IPricePlanCardProps) => {
  const { selectedPricePlan, setSelectedPricePlan } = useManageSubscription();

  const { description } = getPlanDetailsById(plan.pricePlan);

  useEffect(() => {
    if (!selectedPricePlan && plan.isPreferred) {
      setSelectedPricePlan(plan);
    }
  }, []);

  return (
    <StyledCard
      $isSelected={selectedPricePlan?.itemPriceId === plan.itemPriceId}
      onClick={() => setSelectedPricePlan(plan)}
    >
      <Flex vertical gap={24}>
        <Flex vertical gap={8}>
          <Title level={2} $noMargin>
            {plan.catalogFeatures.externalName}
          </Title>
          <Paragraph style={{ margin: 0 }} type="secondary">
            {description}
          </Paragraph>
        </Flex>
        <Flex align="center" gap={12}>
          <Title $noMargin style={{ fontSize: 48 }}>
            {Currency[currency as keyof typeof Currency]}
            {plan.tier.monthlyPrice}
          </Title>
          <Text style={{ color: colors.gray[80] }}>per month</Text>
        </Flex>
        <Flex vertical gap={10}>
          <FeaturesList plan={plan} />
          <Divider style={{ marginBottom: 6 }} />
          <Link underline style={{ fontWeight: 500 }}>
            View plan details
          </Link>
        </Flex>
      </Flex>
    </StyledCard>
  );
};

export default PricePlanCard;
