import styled from 'styled-components';

import Card from 'components/Card';

export const StyledCard = styled(Card)<{ $isSelected: boolean }>`
  width: 280px;
  min-width: 280px;
  cursor: pointer;
  border: ${(props) =>
    props.$isSelected ? `2px solid ${props.theme.colors.primary}` : `1px solid ${props.theme.colors.gray[50]}`};
`;
