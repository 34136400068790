import { Button } from 'antd';
import { ButtonProps } from 'antd/lib';
import { useState } from 'react';

import TermsAndConditions from 'components/TermsAndConditions';

import { Footer } from './styled';

interface IFooterProps {
  buttons: Array<
    {
      isShown: boolean;
      key: string;
    } & ButtonProps
  >;
  showTerms: boolean;
}

export default ({ buttons, showTerms }: IFooterProps) => {
  const [disabled, setDisabled] = useState(showTerms);

  return (
    <Footer>
      {showTerms && <TermsAndConditions setIsCheckboxValid={(isValid) => setDisabled(!isValid)} />}
      {buttons.map(({ isShown, title, ...props }) =>
        isShown ? (
          <Button {...props} disabled={disabled || props.disabled}>
            {title}
          </Button>
        ) : null,
      )}
    </Footer>
  );
};
