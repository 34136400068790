/* eslint-disable @typescript-eslint/no-unused-vars */
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { PaymentIntent } from '@chargebee/chargebee-js-types';
import { Flex } from 'antd';
import { FormInstance } from 'antd/lib';
import { forwardRef, Ref, useImperativeHandle, useRef } from 'react';

import { createPaymentIntent, upgradeSubscriptionNew } from 'api/requests';
import { SubscriptionDto, SubscriptionPricePlan } from 'api/requests/generated/generated.schemas';
import CardPayment from 'components/CardPayment';
import { WebSubscription } from 'components/Icons';
import Spin from 'components/Spin';
import { Title, Text } from 'components/Typography';
import { useChargebee, useManageSubscription, useMessage } from 'lib/hooks';

import { PayByCardWrapper } from './styled';

interface IPaymentInformationFormProps {
  nameForm: FormInstance<{ name: string }>;
  onFormValidation: (isValid: boolean) => void;
}
export interface RefType {
  onConfirmPayment: (values: {
    pricePlan: SubscriptionPricePlan;
    subscription: SubscriptionDto;
    amount: number;
    accountId: string;
  }) => Promise<unknown | null | undefined>;
}

const PaymentInformationForm = ({ nameForm, onFormValidation }: IPaymentInformationFormProps, ref: Ref<RefType>) => {
  const cardRef = useRef<ChargebeeComponents>(null);
  const chargebeeStatus = useChargebee();
  const { selectedPricePlan } = useManageSubscription();
  const message = useMessage();

  useImperativeHandle(ref, () => ({
    async onConfirmPayment({ subscription, accountId, amount, pricePlan }) {
      try {
        if (cardRef.current && selectedPricePlan) {
          const { body: paymentIntent } = await createPaymentIntent(accountId, {
            // TODO replace with estimation amount
            amount,
            currencyCode: subscription.currencyCode,
          });

          const { active_payment_attempt: activePaymentAttempt } = await cardRef.current.authorizeWith3ds(
            paymentIntent as unknown as PaymentIntent,
            { cardBillingAddress: { firstName: nameForm.getFieldValue('name') } },
            { error: () => message.error('Unfortunately your request could not be processed. Please try again.') },
          );

          if (activePaymentAttempt?.id_at_gateway) {
            await upgradeSubscriptionNew(subscription.id, {
              gatewayToken: activePaymentAttempt.id_at_gateway,
              pricePlan,
              quantity: subscription.quantity,
            });
          }
        }
        return null;
      } catch (error) {
        message.error(error.response?.data.error?.msg);
      }
    },
  }));

  return (
    <Flex vertical gap={20}>
      <Title>Payment Information</Title>
      <PayByCardWrapper gap={20}>
        <WebSubscription color="blue" />
        <Text weight={400}>Pay by Credit Card</Text>
      </PayByCardWrapper>
      {chargebeeStatus?.isLoading ? (
        <Spin />
      ) : (
        chargebeeStatus?.isInited && <CardPayment cardRef={cardRef} nameForm={nameForm} onError={onFormValidation} />
      )}
      <Text type="secondary" size="sm">
        By confirming your payment, you agree that Usercentrics GmbH may charge your card for this and future payments
        in accordance with its terms.
      </Text>
    </Flex>
  );
};

export default forwardRef(PaymentInformationForm);
