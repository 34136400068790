import { Flex, Row } from 'antd';

import { useSubscriptionQuota } from 'api/requests';
import {
  AccountOutDto,
  SubscriptionDto,
  SubscriptionPricePlan,
  SubscriptionStatus,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import Card from 'components/Card';
import { Clock, WebSubscription, Lightning as LightningIcon } from 'components/Icons';
import SubscriptionCardContent from 'components/SubscriptionCard/Content';
import Tag, { PremiumTag } from 'components/Tag';
import { Text } from 'components/Typography';
import { OnePricingPricePlans, QuotaNames, TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { isOnePricingSub } from 'lib/contexts/Entitlement';
import { getPlanName } from 'lib/helpers';
import { trackEvent } from 'lib/helpers/amplitude';
import { getDaysLeft } from 'lib/helpers/date';
import { useManageSubscription, useUpgradePlan } from 'lib/hooks';
import { Counter } from 'shared-components';

import { TrialInfoBar } from './styled';
import { getColorByStatus, getSubscriptionStatusName, getItemsToShow } from './utils';

interface IProps {
  subscription: SubscriptionDto | null;
  account: Omit<AccountOutDto, 'subscriptions'> | null;
  isReadOnly?: boolean;
  isBillingPage?: boolean;
  onAssignConfigurations?: () => void;
}

const SubscriptionCard = ({ isBillingPage, isReadOnly, subscription, account, onAssignConfigurations }: IProps) => {
  const { setPlanToUpgrade } = useUpgradePlan();
  const { open } = useManageSubscription();

  const { data } = useSubscriptionQuota(isOnePricingSub(subscription) && subscription ? subscription.id : '', {
    filter: QuotaNames.UNIQUE_DOMAINS,
  });

  if (!subscription) {
    return null;
  }

  const onUpgrade = () => {
    trackEvent(TrackingEvents.CTA_TRIAL_BAR_UPGRADE, {
      [TrackingVariables.SOURCE_PAGE]: 'Account',
    });

    if (account) {
      setPlanToUpgrade({
        ...subscription,
        accountName: account.name,
        billingCustomerId: account.id,
        vatNumber: account.vatNumber as string,
        country: account.country,
      });
    }
  };

  const daysLeft = getDaysLeft(subscription.trialFinishedAt);
  const domains = data?.body?.quota?.[QuotaNames.UNIQUE_DOMAINS]?.limit || 'unlimited';

  const showUpgradeButton = subscription.showUpgradeButton && subscription.status !== SubscriptionStatus.cancelled;
  const isExpiredTrial =
    OnePricingPricePlans.includes(subscription.pricePlan as SubscriptionPricePlan) &&
    subscription.status === SubscriptionStatus.cancelled &&
    subscription.initialTrialFinishedAt !== null &&
    subscription.trialFinishedAt === subscription.initialTrialFinishedAt;

  return (
    <Card
      styles={{
        body: {
          padding: 16,
          maxWidth: 450,
        },
        header: { padding: 16 },
      }}
      borderedFooter
      title={
        <Flex justify="space-between" align="center">
          <Flex gap={8} align="center">
            <Tag bordered $bolder color="blue-inverse" size="middle">
              <WebSubscription color="blue" />
            </Tag>
            <Text weight={500} ellipsis={{ tooltip: true }} style={{ maxWidth: 150 }}>
              {getPlanName(subscription?.pricePlan, subscription?.type)}
            </Text>
          </Flex>
          {daysLeft && daysLeft < 0 ? (
            <Tag size="medium" bordered color="gold">
              Trial expired
            </Tag>
          ) : (
            <Tag size="medium" bordered color={getColorByStatus(subscription.status, isExpiredTrial)}>
              {getSubscriptionStatusName(subscription.status, isExpiredTrial)}
            </Tag>
          )}
        </Flex>
      }
      footer={
        isBillingPage && (
          <Flex justify="space-between">
            <Flex align="center" gap={8}>
              Configurations
              <Counter
                count={subscription.actualSettingsCount}
                data-testid="counter:settings-on-subscriptions"
                showZero
              />
            </Flex>
            {!isReadOnly && isBillingPage && onAssignConfigurations && (
              <Button
                onClick={onAssignConfigurations}
                disabled={subscription.status === 'cancelled'}
                data-testid="button:assign-configurations"
              >
                Assign Configurations
              </Button>
            )}
          </Flex>
        )
      }
    >
      {OnePricingPricePlans.includes(subscription.pricePlan as Partial<SubscriptionPricePlan>) && (
        <>
          {subscription.status === SubscriptionStatus.in_trial && (
            <TrialInfoBar type="active" gap={16} align="center">
              <PremiumTag color="orange-inverse" size="square-medium" bordered={false} />
              <Flex vertical>
                <Text weight={500}>
                  Premium Trial: {daysLeft} day{daysLeft > 1 ? 's' : ''} left
                </Text>
                <Text weight={400} type="secondary" size="sm">
                  Explore all premium features during your trial.
                </Text>
              </Flex>
            </TrialInfoBar>
          )}
          {isExpiredTrial && (
            <TrialInfoBar type="expired" gap={16} align="center">
              <Tag color="white" size="middle">
                <Clock color="orange" />
              </Tag>
              <Flex vertical>
                <Text weight={500}>Your Premium Trial has ended</Text>
                <Text weight={400} type="secondary" size="sm">
                  Upgrade your plan to regain full access to your account.
                </Text>
              </Flex>
            </TrialInfoBar>
          )}
        </>
      )}

      <Row>
        {account && (
          <SubscriptionCardContent
            subscription={subscription}
            domains={domains}
            account={{
              paymentSources: account?.paymentSources,
              primaryPaymentSourceId: account?.primaryPaymentSourceId,
            }}
            itemsToShow={getItemsToShow(isBillingPage)[subscription.status] || []}
          />
        )}
      </Row>
      {isBillingPage &&
        (isExpiredTrial || isOnePricingSub(subscription) ? (
          <Button type="primary" style={{ margin: '8px', padding: '10px 16px' }} onClick={() => open(subscription.id)}>
            Manage subscription
          </Button>
        ) : (
          !isReadOnly &&
          showUpgradeButton && (
            <Button
              data-testid="button:upgrade-trial"
              onClick={onUpgrade}
              icon={<LightningIcon />}
              style={{ marginLeft: '12px', marginTop: '16px', width: '110px' }}
            >
              Upgrade
            </Button>
          )
        ))}
    </Card>
  );
};

export default SubscriptionCard;
